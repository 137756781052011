<template>
  <div class="has-nav-bar view-user-address-books">
    <van-nav-bar
      title="应急通讯"
      :border="false"
      fixed
      left-arrow
      @click-left="onClickLeft"
    />
    <van-search
      v-model="keywords"
      placeholder="请输入企业或人员名称关键词"
      class="search-bar"
      @search="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div class="user-address-books-list">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <template v-for="item in searchList">
          <van-cell
            v-if="!item.mobile"
            :key="item.id"
            :title="item.name"
            :value="item.count ? item.count + '人' : ''"
            icon="ico-company"
            icon-prefix="iconfont"
            :to="{
              name: 'CommunicationSys',
              params: { sys: item }
            }"
          />
          <van-cell
            v-else
            :key="item.id + item.name"
            :title="item.name"
            icon="ico-por"
            icon-prefix="iconfont"
            class="user-address-books-mobile"
          >
            <template #default>
              <a :href="'tel:' + item.mobile">{{ item.mobile }}</a>
            </template>
          </van-cell>
        </template>
      </van-list>
    </div>
  </div>
</template>

<script>
import { NavBar, Cell, Tag, Tabs, Tab, List, Search } from "vant";
import { mapActions, mapState } from "vuex";
import {
  getEmergencyOrgData,
  getAddressBooks
  // getEmergencyUserByPage,
  // getEmergencyUserInfo,
} from "@/api/urgent.js";
export default {
  name: "UserAddressBooks",
  components: {
    [NavBar.name]: NavBar,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [List.name]: List
  },
  data() {
    return {
      keywords: "",
      loading: false,
      finished: false,
      list: [],
      searchList: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.getInfos();
  },
  methods: {
    ...mapActions(["getAddressBooks"]),
    getInfos() {
      getEmergencyOrgData({ orgCode: "YN2001002" }).then(res => {
        res.forEach(item => {
          this.getPersonNum(item.id, item);
        });
      });
    },
    getPersonNum(id, data) {
      const params = {
        orgCode: this.userInfo.orgCode,
        groupId: id
      };
      getAddressBooks(params).then(res => {
        data.count = res && res.length;
        this.list = [data];
        this.searchList = this.list;
      });
    },
    onClickLeft() {
      this.$router.back();
    },
    onLoad() {
      this.finished = true;
    },
    onSearch() {
      if (!this.keywords) {
        return (this.searchList = []);
      }
      const list = [];
      const fn = data => {
        data.map(item => {
          if (item.name.includes(this.keywords)) {
            list.push(item);
          }
          if (item.children) {
            fn(item.children);
          }
        });
      };
      fn(this.list);
      this.searchList = list;
    }
  }
};
</script>
<style lang="scss">
.view-user-address-books {
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  &.has-nav-bar {
    padding-top: 140px;
  }
  .van-cell__title {
    flex: 7;
  }
  .search-bar {
    position: fixed;
    top: 58px;
    z-index: 1;
    width: 100%;
  }
  .user-address-books-list {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .van-cell {
      padding-top: 20px;
      padding-bottom: 20px;
      color: #3b4664;
      font-size: 15px;
      &:not(:last-child)::after {
        left: 16px;
        right: 16px;
      }
      .van-cell__left-icon {
        margin-right: 10px;
      }
      .van-cell__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
      }
      .van-cell__value {
        color: #aeb3c0;
        white-space: nowrap;
        flex: none;
        a {
          color: #aeb3c0;
        }
      }
    }
    .user-address-books-mobile {
      .van-cell__title {
        flex: 1;
      }
    }
  }
}
</style>
